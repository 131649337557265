<template>
  <div>
    <a-form
      class="custom-compact-form"
      :form="form"
      :label-col="{ span: 1 }"
      :wrapper-col="{ span: 20 }"
      @submit="handleSubmit"
    >

      <a-form-item>
        <wang-editor-frequently-asked-question
          v-decorator="['content', {
            rules: [
              { max: 2000, message: '最多2000个字符' },
            ]
          }]"
        />
      </a-form-item>
      <a-form-item class="set-button-center">
        <a-button type="primary" html-type="submit" :loading="submitting">
          保存
        </a-button>
      </a-form-item>

    </a-form>

  </div>
</template>

<script>
import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'
import { findFrequentlyAskedQuestions, updateFrequentlyAskedQuestions } from '@/api/frequently_asked_question'

export default {
  name: 'IndexFrequentlyAskedQuestion',
  components: {
    WangEditorFrequentlyAskedQuestion
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'frequently_asked_question_edit' }),
      submitting: false
    }
  },
  created() {
    this.initEditor()
  },
  methods: {
    initEditor() {
      // 访问编辑框的时候加载以前保存的内容
      findFrequentlyAskedQuestions().then((res) => {
        this.form.setFieldsValue({
          content: res.data.content
        })
      })
    },

    // 保存事件
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          updateFrequentlyAskedQuestions(values).then((res) => {
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.set-button-center {
  text-align: center;
}
::v-deep .w-e-menu {
  z-index: 2 !important;
}
::v-deep .w-e-text-container {
  z-index: 1 !important;
}
::v-deep .w-e-toolbar {
  z-index: 2 !important;
}
</style>
